<template>
    <section class="o-carousel">
        <div class="l-container">
            <div class="l-row l-row--center">
                <div class="l-row__column l-row__column--8">
                    <div
                        class="o-carousel__content"
                        v-html="data.content"
                    ></div>
                </div>
            </div>
        </div>
        <div class="l-container">
            <div class="l-row l-row--center">
                <div class="l-row__column">
                    <div
                        class="m-slider"
                        ref="mSlider"
                    >
                        <div
                            v-for="(slide, slideIdx) in data.carousel"
                            v-bind:key="`slide${slideIdx}`"
                            class="m-slider__item"
                            :class="{'m-slider__item--active': item === slideIdx}"
                            @click="slide.link ? window.location.href = slide.link : false"
                            v-touch:swipe.left="next"
                            v-touch:swipe.right="prev"
                        >
                            <div
                                class="m-slider__itemContent"
                                v-html="slide.content"
                            ></div>
                            <div class="m-slider__itemFooter">
                                <the-image
                                    class-image="m-slider__itemImage"
                                    :image="slide.image"
                                    :sizes="['80','80','80','80']"
                                />
                                <div
                                    class="m-slider__itemDescription"
                                    v-html="slide.description"
                                ></div>
                            </div>
                        </div>
                        <div
                            v-if="data.carousel.length > 1"
                            class="m-slider__nav"
                        >
                            <div
                                class="m-slider__navItem"
                                @click="prev"
                            >
                                <svg class="m-slider__navArrow m-slider__navArrow--prev" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.98 2l-16 16 16 16" stroke="#7F7F7F" stroke-width="1"/>
                                </svg>
                            </div>
                            <div class="m-slider__navItems">
                                <div
                                    v-for="(slide, slideIdx) in data.carousel"
                                    v-bind:key="`slideNav${slideIdx}`"
                                    class="m-slider__navItem"
                                    @click="item = slideIdx"
                                >
                                    <svg class="m-slider__navItemIcon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            class="m-slider__navItemIconPath"
                                            :stroke="slideIdx === item ? '#fff' : '#7F7F7F'"
                                            :stroke-width="slideIdx === item ? 3 : 1"
                                            d="M3.717 14.756V.676M11.763 14.756V.676M14.78 11.739H.7M14.78 3.693H.7M3.717 3.693h8.046v8.046H3.717z"
                                            stroke-miterlimit="10"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div
                                class="m-slider__navItem"
                                @click="next"
                            >
                                <svg class="m-slider__navArrow m-slider__navArrow--next" width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02 33l16-16-16-16" stroke="#7F7F7F" stroke-miterlimit="10"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            item: 0,
            interval: null
        }
    },
    watch: {
        item() {
            this.$nextTick(() => {
                this.calcHeight()
            })
        }
    },
    methods: {
        calcHeight() {
            const styles = window.getComputedStyle(document.querySelector('.m-slider__item--active'))
            this.$refs.mSlider.style.height = styles.height
        },
        next() {
            this.item === this.data.carousel.length - 1 ? this.item = 0 : this.item++
            this.initInterval()
        },
        prev() {
            this.item === 0 ? this.item = this.data.carousel.length - 1 : this.item--
            this.initInterval()
        },
        initInterval() {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                this.next()
            }, this.data.speed)
        }
    },
    mounted() {
        this.calcHeight()
        this.initInterval()
    },
    destroyed() {
        clearInterval(this.interval)
    }
}
</script>