<template>
    <div class="o-projectsList">
        <div class="l-container">
            <div class="l-row">
                <div class="l-row__column">
                    <h1 class="a-title o-projectsList__title">{{ data.title }}</h1>
                </div>
            </div>
            <div class="l-row">
                <div class="l-row__column">
                    <div class="o-projectsList__nav">
                        <button
                            class="o-projectsList__navButton"
                            :class="{'o-projectsList__navButton--active':category.slug === currentCategory}"
                            v-for="(category, categoryIdx) in categories"
                            v-bind:key="`category${categoryIdx}`"
                            @click="navToProjects(category.slug)"
                        >{{ category.name }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="l-container l-container--fluid o-projectsList__grid">
            <div
                class="m-grid"
                ref="grid"
                v-if="projects"
            >
                <div
                    v-for="(project, projectIdx) in projects"
                    v-bind:key="`project${projectIdx}`"
                    class="m-grid__item"
                    :class="`category-${project.category.slug}`"
                    :data-category="project.category.slug"
                    @click="$router.push({ name: 'project', params: { id: project.slug } })"
                >
                    <div
                        class="m-grid__itemImage"
                        :style="{'background-image': `url('${image(project.image)}')`}"
                    >
                        <div class="m-grid__itemImage">
                            <div class="m-grid__itemContent">
                                <p class="m-grid__itemTitle">{{ project.title }}</p>
                                <p
                                    v-if="project.category"
                                    class="m-grid__itemSubtitle"
                                >{{ project.category.name }}</p>
                                <div class="m-grid__itemBorderTop"></div>
                                <div class="m-grid__itemBorderBottom"></div>
                                <div class="m-grid__itemBorderLeft"></div>
                                <div class="m-grid__itemBorderRight"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Isotope from 'isotope-layout'
export default {
    data() {
        return {
            isotope: null,
            currentCategory: '*'
        }
    },
    computed: {
        projects() {
            return this.$store.getters.getProjects
        },
        categories() {
            return [
                {
                    name: 'Wszystkie',
                    slug: '*'
                },
                ...this.$store.getters.getProjectsCategories
            ]
        }
    },
    watch: {
        currentCategory() {
            this.isotope.arrange({
                filter: (itemElem) => this.currentCategory === '*' ? true : itemElem.classList.contains(`category-${this.currentCategory}`)
            })
        }
    },
    methods: {
        navToProjects(slug) {
            this.currentCategory = slug
            // change url with vanillajs
            if(slug === '*') {
                history.pushState({}, 'Projekty', '/projekty')
            } else {
                history.pushState({}, 'Projekty', `/projekty/${slug}`)
            }
        },
        image(image) {
            return window.iOS() ? image : `${image}.webp` || '/imageholder.jpg'
        }
    },
    mounted() {
        const grid = this.$refs.grid
        this.isotope = new Isotope(grid, {
            itemSelector: '.m-grid__item',
            layoutMode: 'fitRows',
            // if category then init isotope with arrange()
            initLayout: this.$route.params.id === 'projekty',
            masonry: {
                columnWidth: '.m-grid__item'
            }
        })
        // if category then arrange()
        if(this.$route.params.id !== 'projekty') {
            this.currentCategory = this.$route.params.id
        }
    }
}
</script>