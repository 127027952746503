<template>
    <div class="o-project">
        <div class="o-slider o-slider--small">
            <div class="o-slider__carousel">
                <div class="o-slider__item o-slider__item--active">
                    <the-image
                        class-image="o-slider__itemImage"
                        :image="project.images"
                        :sizes="['1024','1129','1290','1703']"
                    />
                    <div class="l-container o-slider__itemContent">
                        <div class="l-row">
                            <div class="l-row__column">
                                <h1
                                    class="o-slider__itemTitle"
                                    v-html="project.title"
                                ></h1>
                                <h2
                                    v-if="project.acf.subtitle"
                                    class="o-slider__itemSubtitle"
                                    v-html="project.acf.subtitle"
                                ></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="o-project__logoWrapperMobile">
            <img
                class="o-project__logo"
                :src="project.acf.logo.url"
            />
        </div>
        <div
            class="o-project__details"
            :class="{'o-project__details--logo': project.acf.logo.url}"
        >
            <div class="l-container">
                <div
                    v-if="project.acf.content.length > 0"
                    class="l-row"
                >
                    <div class="l-row__column l-row__column--8">
                        <h3 class="o-project__detailsTitle">Szczegóły projektu</h3>
                        <div
                            class="l-row"
                            v-for="(detail, detailIdx) in project.acf.content"
                            v-bind:key="`details${detailIdx}`"
                        >
                            <div class="l-row__column l-row__column--4 o-project__detailsLabel">
                                <p v-html="detail.info.label"></p>
                            </div>
                            <div class="l-row__column o-project__detailsValue">
                                <p v-html="detail.info.value"></p>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="project.acf.logo && project.acf.logo.url"
                        class="l-row__column o-project__logoWrapper"
                    >
                        <img
                            class="o-project__logo"
                            :src="project.acf.logo.url"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="o-project__gallery">
            <div class="l-container">
                <div class="l-row l-row--multiline">
                    <div
                        class="l-row__column l-row__column--12"
                        v-for="(photo, photoIdx) in project.acf.gallery"
                        v-bind:key="`photo${photoIdx}`"
                        @click="openModal(photoIdx)"
                    >
                        <the-image
                            class-image="o-project__galleryImage"
                            :image="photo"
                            :sizes="['977','838','977','1129']"
                        />
                    </div>
                </div>
            </div>
        </div>
        <the-modal name="project">
            <the-image
                class-image="o-modal__image"
                :image="currentImage"
                :sizes="['1024','1129','1290','1770']"
                v-touch:swipe.left="next"
                v-touch:swipe.right="prev"
            />
            <div
                v-if="project.acf.gallery.length > 1"
                class="o-modal__nav"
            >
                <button
                    class="o-modal__navButton o-modal__navButton--prev"
                    @click="prev"
                >
                    <svg class="o-modal__navButtonArrow" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.98 2l-16 16 16 16" stroke="#000" stroke-width="1"/>
                    </svg>
                </button>
                <button
                    class="o-modal__navButton o-modal__navButton--next"
                    @click="next"
                >
                    <svg class="o-modal__navButtonArrow" width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.02 33l16-16-16-16" stroke="#000" stroke-miterlimit="10"/>
                    </svg>
                </button>
            </div>
        </the-modal>
    </div>
</template>

<script>
import TheModal from '../components/TheModal.vue'
export default {
    name: 'page',
    data() {
        return {
            modalPhotoId: null
        }
    },
    components: {
        TheModal
    },
    methods: {
        openModal(photoId) {
            this.modalPhotoId = photoId
            this.$store.dispatch('modalShow', 'project')
        },
        next() {
            this.modalPhotoId >= this.project.acf.gallery.length - 1 ? this.modalPhotoId = 0 : this.modalPhotoId++
        },
        prev() {
            this.modalPhotoId < 1 ? this.modalPhotoId = this.project.acf.gallery.length - 1 : this.modalPhotoId--
        },
        keySupport(event) {
            switch(event.keyCode) {
            case 39:
                this.next()
                break
            case 37:
                this.prev()
                break
            case 27:
                this.$store.dispatch('modalHide', 'project')
                break
            }
        }
    },
    computed: {
        project() {
            return this.$store.getters.getPageByPath(this.$route.path)
        },
        currentImage() {
            return this.project.acf.gallery[this.modalPhotoId]
        }
    },
    mounted() {
        window.addEventListener('keydown', event => this.keySupport(event))
    },
    destroted() {
        window.removeEventListener('keydown', event => this.keySupport(event))
    }
}
</script>
