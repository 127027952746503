<template>
    <div>
        <section class="l-section o-content">
            <div class="l-container o-content__content">
                <div class="l-row">
                    <div class="l-row__column l-row__column--6">
                        <h1>Kontakt</h1><br />
                        <a
                            href="http://www.google.com/maps/place/52.408520,16.936657"
                            target=_blank
                        >
                            <strong>Dementi Sp. z o.o. Sp. k.</strong><br />
                            ul. Wielka 21, 61-775 Poznań<br />
                            NIP 778-146-89-82
                        </a>
                    </div>
                    <div class="l-row__column l-row__column--6 o-content__contact">
                        <a href="tel:tel:48612236291">
                            +48 61 223 62 91
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="l-section o-content__image o-content__image">
            <div class="l-container">
                <div class="l-row">
                    <div class="l-row__column">
                        <the-image
                            class-image="o-content__contactImage"
                            :image="data.image"
                            :sizes="['977','838','977','1129']"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section class="l-section o-formAndMap">
            <div class="l-container o-formAndMap__contentContainer">
                <div class="l-row o-formAndMap__contentRow">
                    <div class="l-row__column o-formAndMap__contentColumn">
                        <form @submit.prevent="sendMail">
                            <h2 class="o-formAndMap__title">
                                Masz pytania?
                            </h2>
                            <div class="o-formAndMap__form">
                                <div class="a-control o-formAndMap__input">
                                    <label
                                        for="name"
                                        class="a-control__label"
                                    >Imię i nazwisko</label>
                                    <input
                                        class="a-control__input o-formAndMap__inputField"
                                        id="name"
                                        type="text"
                                        name="name"
                                        v-model="formName"
                                        required
                                    >
                                </div>
                                <div class="a-control o-formAndMap__input">
                                    <label
                                        for="name"
                                        class="a-control__label"
                                    >Telefon</label>
                                    <input
                                        class="a-control__input o-formAndMap__inputField"
                                        id="phone"
                                        type="text"
                                        name="phone"
                                        v-model="formPhone"
                                    >
                                </div>
                                <div class="a-control o-formAndMap__textarea">
                                    <label
                                        for="name"
                                        class="a-control__label"
                                    >Treść wiadomości</label>
                                    <textarea
                                        class="a-control__input o-formAndMap__textareaField"
                                        id="message"
                                        name="message"
                                        v-model="formMessage"
                                    ></textarea>
                                </div>
                                <div class="o-formAndMap__regulations">
                                    <input
                                        required
                                        type="checkbox"
                                        class="o-formAndMap__regulationsCheckbox"
                                        id="regulations"
                                    >
                                    <label for="regulations">
                                        <p>
                                            Zgadzam się na przetwarzanie moich danych osobowych przez firmę Dementi Sp. z o.o. Sp. k z siedzibą w Poznaniu 61-775, ul. Wielka 21, NIP: 778-146-89-82, REGON: 301340734 w zakresie niezbędnym do udzielenia informacji na wysłane zapytanie w formularzu kontaktowym.
                                        </p>
                                        <p>
                                            Zgoda na przetwarzanie dotyczy następujących danych osobowych:<br />
                                            - imię i nazwisko<br />
                                            - telefon
                                        </p>
                                        <p>
                                            Dane są przetwarzane zgodnie z
                                            <a href="/polityka-prywatnosci/" target="_blank">Polityką Prywatności</a>
                                            . Mam prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego lub przeniesienia danych.
                                        </p>
                                    </label>
                                </div>
                                <br /><br />
                                <input
                                    class="a-button a-button--styled o-formAndMap__formSubmit"
                                    type="submit"
                                    value="Wyślij"
                                >
                            </div>
                            <transition name="fade" mode="out-in">
                                <div
                                    v-if="resultMessage"
                                    class="o-formAndMap__messageWrapper"
                                >
                                    <p class="o-formAndMap__message">{{ resultMessage }}</p>
                                </div>
                            </transition>
                        </form>
                    </div>
                    <div class="l-row__column o-formAndMap__mapColumn">
                        <GmapMap
                            :center="mapCenter"
                            :zoom="14"
                            class="o-formAndMap__map"
                            :options="options"

                        >
                            <GmapMarker
                                :position="mapCenter"
                            />
                        </GmapMap>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formName: '',
            formPhone: '',
            formMessage: '',
            resultMessage: '',
            mapCenter: {
                lat: 52.408520,
                lng: 16.936657
            },
            options: {
                disableDefaultUI: true,
                styles: [{
                    featureType: 'all',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        saturation: 36
                    }, {
                        color: '#000000'
                    }, {
                        lightness: 40
                    }]
                }, {
                    featureType: 'all',
                    elementType: 'labels.text.stroke',
                    stylers: [{
                        visibility: 'on'
                    }, {
                        color: '#000000'
                    }, {
                        lightness: 16
                    }]
                }, {
                    featureType: 'all',
                    elementType: 'labels.icon',
                    stylers: [{
                        visibility: 'off'
                    }]
                }, {
                    featureType: 'administrative',
                    elementType: 'geometry.fill',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 20
                    }]
                }, {
                    featureType: 'administrative',
                    elementType: 'geometry.stroke',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 17
                    }, {
                        weight: 1.2
                    }]
                }, {
                    featureType: 'landscape',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 20
                    }]
                }, {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 21
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'geometry.fill',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 17
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 29
                    }, {
                        weight: 0.2
                    }]
                }, {
                    featureType: 'road.arterial',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 18
                    }]
                }, {
                    featureType: 'road.local',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 16
                    }]
                }, {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 19
                    }]
                }, {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#000000'
                    }, {
                        lightness: 17
                    }]
                }]
            }
        }
    },
    watch: {
        resultMessage() {
            if(this.resultMessage !== '') {
                setTimeout(() => {
                    this.resultMessage = ''
                }, 2000)
            }
        }
    },
    methods: {
        clearForm() {
            this.formName = ''
            this.formPhone = ''
            this.formMessage = ''
        },
        sendMail() {
            this.$store.dispatch('sendMail', {
                'your-name': this.formName,
                'your-phone': this.formPhone,
                'your-message': this.formMessage
            }).then(() => {
                this.resultMessage = 'Wiadomość została wysłana!'
                this.clearForm()
            }).catch(error => {
                console.error(error)
                this.resultMessage = 'Błąd z wysłaniem wiadomości'
            })
        }
    }
}
</script>
