<template>
    <div class="o-app__page">
        <flexible-content/>
    </div>
</template>

<script>
import FlexibleContent from '@/components/FlexibleContent.vue'
export default {
    name: 'page',
    components: {
        FlexibleContent
    }
}
</script>