<template>
    <div class="o-slider o-slider--main">
        <div class="o-slider__nav">
            <div
                class="o-slider__navItem"
                @click="prev"
            >
                <svg class="o-slider__navArrow o-slider__navArrow--prev" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.98 2l-16 16 16 16" stroke="#7F7F7F" stroke-width="1"/>
                </svg>
            </div>
            <div
                v-for="(slide, slideIdx) in data.slider"
                v-bind:key="`slideNav${slideIdx}`"
                class="o-slider__navItem"
                @click="setSlide(slideIdx)"
            >
                <svg class="o-slider__navItemIcon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        class="o-slider__navItemIconPath"
                        :stroke="slideIdx === item ? '#fff' : '#7F7F7F'"
                        :stroke-width="slideIdx === item ? 3 : 1"
                        d="M3.717 14.756V.676M11.763 14.756V.676M14.78 11.739H.7M14.78 3.693H.7M3.717 3.693h8.046v8.046H3.717z"
                        stroke-miterlimit="10"
                    />
                </svg>
            </div>
            <div
                class="o-slider__navItem"
                @click="next"
            >
                <svg class="o-slider__navArrow o-slider__navArrow--next" width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.02 33l16-16-16-16" stroke="#7F7F7F" stroke-miterlimit="10"/>
                </svg>
            </div>
        </div>
        <div class="o-slider__carousel">
            <div
                v-for="(slide, slideIdx) in data.slider"
                v-bind:key="`slide${slideIdx}`"
                class="o-slider__item"
                :class="{'o-slider__item--active': item === slideIdx}"
                @click.stop="openLink(slide.link)"
                v-touch:swipe.left="next"
                v-touch:swipe.right="prev"
            >
                <the-image
                    class-image="o-slider__itemImage"
                    :image="slide.image"
                    :sizes="['1024','1129','1290','1770']"
                />
                <div class="l-container o-slider__itemContent">
                    <div class="l-row">
                        <div class="l-row__column">
                            <h1
                                class="o-slider__itemTitle"
                                v-html="slide.title"
                            ></h1>
                            <h2
                                v-if="slide.subtitle"
                                class="o-slider__itemSubtitle"
                                v-html="slide.subtitle"
                            ></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item: 0
        }
    },
    methods: {
        next() {
            this.item === this.data.slider.length - 1 ? this.item = 0 : this.item++
            this.initInterval()
        },
        prev() {
            this.item === 0 ? this.item = this.data.slider.length - 1 : this.item--
            this.initInterval()
        },
        setSlide(slideIdx) {
            this.item = slideIdx
            this.initInterval()
        },
        initInterval() {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                this.next()
            }, this.data.speed)
        },
        openLink(link) {
            if(link) {
                window.location.href = link
            }
        }
    },
    mounted() {
        this.initInterval()
    },
    destroyed() {
        clearInterval(this.interval)
    }
}
</script>