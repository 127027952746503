<template>
    <section class="l-section o-categories">
        <div class="l-container">
            <div class="l-row">
                <div
                    v-for="category in data.categories"
                    v-bind:key="`category-${category.slug}`"
                    class="l-row__column"
                    @click="$router.push({ name: 'subpage', params: { parent: 'projekty', id: category.slug } })"
                >
                    <div class="o-categories__item">
                        <the-image
                            class-image="o-categories__itemImage"
                            :image="category.image"
                            :sizes="['992x512','258x258','305x305','355x355']"
                        />
                        <div class="o-categories__itemContent">
                            <h3 class="o-categories__itemTitle">
                                {{ category.name }}
                            </h3>
                            <p class="o-categories__itemDescription">
                                {{ category.description }}
                            </p>
                            <router-link
                                class="a-readMore o-categories__itemMore"
                                :to="{ name: 'subpage', params: { parent: 'projekty', id: category.slug } }"
                            >
                                Czytaj dalej
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>
