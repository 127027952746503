import Vue from 'vue'
export const modal = {
    state: {
        status: []
    },
    mutations: {
        MODAL_SHOW(state, name) {
            // turn on current modal
            const idx = state.status.findIndex(item => item.name === name)
            if(idx === -1) {
                Vue.set(state.status, state.status.length, {
                    name,
                    state: true
                })
            } else {
                Vue.set(state.status, idx, {
                    name,
                    state: true
                })
            }
        },
        MODAL_HIDE(state) {
            state.status.forEach(item => {
                Vue.set(item, 'state', false)
            })
        },
        MODAL_TOGGLE(state) {
            state.status = !state.status
        }
    },
    actions: {
        modalShow({ commit }, name) {
            commit('MODAL_SHOW', name)
        },
        modalHide({ commit }) {
            commit('MODAL_HIDE')
        },
        modalToggle({ commit }, name) {
            commit('MODAL_HIDE')
        }
    },
    getters: {
        modalStatus: (state) => (name) => {
            if(state.status.find(item => item.name === name) !== undefined) {
                return state.status.find(item => item.name === name).state
            } else return false
        }
    }
}
