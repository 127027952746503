<template>
    <section class="l-section o-contentAndImage">
        <div class="l-container">
            <div class="l-row o-contentAndImage__row">
                <div
                    v-if="data.content"
                    class="l-row__column"
                    :class="{'l-row__column--6':!data.image.url}"
                >
                    <div v-html="data.content"></div>
                </div>
                <div
                    v-if="data.image.url"
                    class="l-row__column"
                    :class="{'l-row__column--6':!data.content}"
                >
                    <the-image
                        class-image="o-contentAndImage__image"
                        :image="data.image"
                        :sizes="['977','403','474','549']"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
}
</script>