<template>
    <section class="l-section o-projects">
        <div class="l-container">
            <div class="l-row">
                <div
                    class="l-row__column o-projects__content"
                    v-html="data.content"
                ></div>
            </div>
        </div>
        <div class="l-container">
            <div class="l-row m-carousel o-projects__carouselWrapper">
                <div class="o-projects__carousel">
                    <div
                        class="o-projects__carouselItems"
                        :class="{ 'o-projects__carouselItems--animated': !disableAnimation }"
                        :style="{'transform': `translateX(calc(var(--o-projects__carouselColumn-width) * -${item}))`}"
                        v-touch:swipe.left="next"
                        v-touch:swipe.right="prev"
                    >
                        <div
                            class="o-projects__carouselColumn"
                            :class="{'o-projects__carouselColumn--active':projectIdx == item}"
                            v-for="(project, projectIdx) in data.projects"
                            v-bind:key="`project-${project.post_name}`"
                            @click="$router.push({ name: 'project', params: { id: project.post_name } })"
                        >
                            <div class="m-carousel__item o-projects__carouselItem">
                                <the-image
                                    class-image="o-projects__carouselItemImage"
                                    :image="project.image"
                                    :sizes="['787x512','318x250','390x301','512x365']"
                                />
                                <div class="o-projects__carouselItemContent">
                                    <h3 class="o-projects__carouselItemTitle">
                                        {{ project.post_title }}
                                    </h3>
                                    <h4 class="o-projects__carouselItemSubtitle">
                                        {{ project.subtitle }}
                                    </h4>
                                    <router-link
                                        class="a-readMore o-projects__carouselItemMore"
                                        :to="{ name: 'project', params: { id: project.post_name } }"
                                    >
                                        Czytaj dalej
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div
                            class="o-projects__carouselColumn"
                            v-for="project in data.projects"
                            v-bind:key="`project2-${project.post_name}`"
                            @click="$router.push({ name: 'project', params: { id: project.post_name } })"
                        >
                            <div class="m-carousel__item o-projects__carouselItem">
                                <the-image
                                    class-image="o-projects__carouselItemImage"
                                    :image="project.image"
                                    :sizes="['787x512','318x250','390x301','512x365']"
                                />
                                <div class="o-projects__carouselItemContent">
                                    <h3 class="o-projects__carouselItemTitle">
                                        {{ project.post_title }}
                                    </h3>
                                    <h4 class="o-projects__carouselItemSubtitle">
                                        {{ project.subtitle }}
                                    </h4>
                                    <router-link
                                        class="a-readMore o-projects__carouselItemMore"
                                        :to="{ name: 'project', params: { id: project.post_name } }"
                                    >
                                        Czytaj dalej
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-slider__nav o-projects__carouselNav">
                        <div
                            class="m-slider__navItem"
                            @click="prev"
                        >
                            <svg width="21" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.98 2l-16 16 16 16" stroke="#7F7F7F" stroke-width="1"/>
                            </svg>
                        </div>
                        <span class="o-projects__carouselNavCounter">{{ item + 1 > data.projects.length ? 1 : item + 1 }} / {{ data.projects.length }}</span>
                        <div
                            class="m-slider__navItem"
                            @click="next"
                        >
                            <svg width="18" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.02 33l16-16-16-16" stroke="#7F7F7F" stroke-miterlimit="10"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            item: 0,
            interval: null,
            disableAnimation: false
        }
    },
    watch: {
        item() {
            if(this.item >= 1) {
                this.disableAnimation = false
            }
        }
    },
    methods: {
        next() {
            this.item === this.data.projects.length - 1 ? this.item = 0 : this.item++
            this.initInterval()
        },
        prev() {
            this.item === 0 ? this.item = this.data.projects.length - 1 : this.item--
            this.initInterval()
        },
        initInterval() {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                this.item++
                if(this.item > this.data.projects.length - 1) {
                    setTimeout(() => {
                        this.disableAnimation = true
                        this.item = 0
                    }, 800)
                }
            }, 3000)
        }
    },
    mounted() {
        if(this.data.projects) {
            this.initInterval()
        }
    },
    destroyed() {
        clearInterval(this.interval)
    }
}
</script>
