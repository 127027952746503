<template>
    <section class="l-section o-content">
        <div class="l-container">
            <div class="l-row o-contentAndImage__row">
                <div class="l-row__column">
                    <div class="o-content__wysiwyg" v-html="data.content"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
}
</script>