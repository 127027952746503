import axios from 'axios'

export const contactForm = {
    state: {
    },
    mutations: {
    },
    actions: {
        sendMail({ commit }, payload) {
            return new Promise((resolve, reject) => {
                const formData = new FormData()
                for(const key in payload) {
                    formData.append(key, payload[key])
                }
                axios.post(`${process.env.VUE_APP_CMS_API}contact-form-7/v1/contact-forms/${process.env.VUE_APP_CMS_FORM_ID}/feedback`, formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if(response.data.status === 'mail_sent') {
                        resolve()
                    } else {
                        reject(response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    getters: {
    }
}