import Vue from 'vue'
import Vuex from 'vuex'
import { cms } from './cms.module'
import { contactForm } from './contactForm.module'
import { modal } from './modal.module'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        cms,
        contactForm,
        modal
    }
})
