<template>
    <div
        v-if="modalStatus"
        class="o-modal"
        @click.self="hideModal"
    >
        <div class="o-modal__box">
            <button
                class="o-modal__close"
                @click="$store.dispatch('modalHide', name)"
            ></button>
            <slot></slot>
            <div class="o-modal__actions">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        }
    },
    computed: {
        modalStatus() {
            return this.$store.getters.modalStatus(this.name)
        }
    },
    methods: {
        hideModal() {
            this.$store.dispatch('modalHide', this.name)
        }
    }
}
</script>