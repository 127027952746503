import axios from 'axios'

export const cms = {
    state: {
        content: null,
        loadingStatus: 'init',
        isPreloaderHidden: false
    },
    mutations: {
        SET_LOADING_STATUS(state, status) {
            state.loadingStatus = status
        },
        SET_CONTENT(state, content) {
            state.content = content
        },
        SET_PRELOADER(state, isPreloaderHidden) {
            state.isPreloaderHidden = isPreloaderHidden
        }
    },
    actions: {
        fetchContent({ commit }) {
            commit('SET_LOADING_STATUS', 'loading')
            return axios.get(`${process.env.VUE_APP_CMS_API}api/v1/content`).then((response) => {
                commit('SET_CONTENT', Object.freeze(response.data))
                commit('SET_LOADING_STATUS', 'idle')
            })
        },
        hidePreloader({ commit }) {
            commit('SET_PRELOADER', true)
        }
    },
    getters: {
        getCmsStatus: (state) => {
            return state.loadingStatus
        },
        getFlexibleContent: (state) => (slug) => {
            if(state.content != null) {
                return state.content.pages.find(page => page.slug === slug).flexible_content
            }
        },
        getPageByPath: (state, getters) => (path) => {
            if(state.content != null) {
                let page = null
                // remove trailing slash
                path = path.replace(/\/$/, '')
                // remove from path public path
                path = path.replace(getters.getPublicPath, '')
                // analyze path without first "/"
                const routes = path.substr(1).split('/')
                // if page has no parent
                if(routes.length === 1) {
                    // if empty then set to default home
                    // get current locale
                    const locale = getters.getLocale
                    // find home slug of current locale
                    const homeSlug = getters.getLanguages.find(lang => lang.lang === locale).home_slug
                    // const homeSlug = '/'
                    const route = routes[0] ? routes[0] : homeSlug
                    page = getters.getPageIdBySlugAndParent(route, 0)
                } else if(routes.length === 2) {
                    if(routes[0] === 'projekt') {
                        page = getters.getProjectBySlug(routes[1])
                    } else if(routes[0] === 'projekty') {
                        page = getters.getPageIdBySlugAndParent('projekty', 0)
                    } else {
                        // get parents object
                        const parent = getters.getPageIdBySlugAndParent(routes[0], 0)
                        page = getters.getPageIdBySlugAndParent(routes[1], parent.id)
                    }
                }
                // more than 2 nesting needs implement

                if(page) {
                    return page
                } else {
                    return null
                }
            }
        },
        getPosts: (state) => {
            if(state.content != null) {
                return state.content.posts
            }
        },
        getBlock: (state) => (id) => {
            if(state.content != null) {
                return state.content.blocks[id].flexible_content
            }
        },
        /* Languages */
        getLanguages: (state) => {
            if(state.content != null) {
                return state.content.languages
            }
        },
        getLocale: (state, getters) => {
            if(state.content != null) {
                // all available languages
                const langs = getters.getOptions.languages

                // set default language
                const defaultLocale = getters.getOptions.default_language

                // get language from url
                const urlLocale = window.location.pathname.replace(process.env.BASE_URL, '/').replace(/^\/([^\\/]+).*/i, '$1')

                // if url has no known language then set default language
                const locale = langs.includes(urlLocale) ? urlLocale : defaultLocale

                return locale
            }
        },
        /* Menus */
        getMainMenu: (state, getters) => {
            if(state.content != null) {
                const locale = getters.getLocale
                return state.content.menus.main_menu[locale]
            }
        },
        /* Options */
        getOptions: (state) => {
            if(state.content != null) {
                return state.content.options
            }
        },
        getOptionsFavicon: (state) => {
            if(state.content != null) {
                return state.content.options.favicon
            }
        },
        getOptionsLogo: (state) => {
            if(state.content != null) {
                return state.content.options.logo
            }
        },
        getSeoTitleTemplate: (state) => {
            if(state.content != null) {
                return state.content.options.seo_title_template
            }
        },
        getPublicPath: (state) => {
            return (process.env.VUE_APP_PUBLIC_PATH === undefined || process.env.VUE_APP_PUBLIC_PATH === null) ? '' : process.env.VUE_APP_PUBLIC_PATH
        },
        /* Specific getters */
        getPageIdBySlugAndParent: (state) => (slug, parent) => {
            if(state.content != null) {
                return state.content.pages.find(page => page.slug === slug && page.parent_id === parent)
            }
        },
        getProjects: (state) => {
            if(state.content != null) {
                return state.content.project
            }
        },
        getProjectBySlug: (state) => (slug) => {
            if(state.content != null) {
                return state.content.project.find(project => project.slug === slug)
            }
        },
        getProjectsCategories: (state) => {
            if(state.content != null) {
                const a = [...state.content.project.map(item => item.category)]
                return a.filter((v, i) => {
                    return a.map(item => item.term_id).indexOf(v.term_id) === i
                })
            }
        },
        isPreloaderHidden: (state) => {
            return state.isPreloaderHidden
        }
    }
}
