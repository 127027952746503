<template>
    <div class="o-cookieModal" :class="{ 'o-cookieModal--active': isActive }">
        <div class="o-cookieModal__text-container">
            <p class="o-cookieModal__title">Informacja Cookie</p>
            <p class="o-cookieModal__text">
                Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. Aby sprawdzić kto jest administratorem
                Twoich danych i w jaki sposób są przetwarzane przejdź do <router-link class="o-cookieModal__link" to="/polityka-prywatnosci/">Polityki Prywatności</router-link>.
            </p>
        </div>
        <button class="a-button o-cookieModal__button" @click="closeModal">Zatwierdź</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        createCookie() {
            const cookieName = 'Polityka Prywatności'
            const cookieDate = new Date()
            cookieDate.setFullYear(cookieDate.getFullYear() + 1)
            document.cookie = `Dementi=${cookieName}; expires= ${cookieDate.toUTCString()};`
        },
        closeModal() {
            this.isActive = !this.isActive
            this.createCookie()
        }
    },
    mounted() {
        setTimeout(() => {
            if (document.cookie.indexOf('Dementi=') === -1) {
                this.isActive = true
            } else {
                this.isActive = false
            }
        }, 2000)
    }
}
</script>