<template>
    <div>
        <section class="l-section o-content">
            <div class="l-container o-content__content">
                <div class="l-row">
                    <div class="l-row__column l-row__column--5">
                        <div v-html="data.content"></div>
                    </div>
                </div>
            </div>
        </section>
        <section class="l-section o-content__image">
            <div class="l-container">
                <div class="l-row">
                    <div class="l-row__column">
                        <the-image
                            class-image="o-content__imageImg"
                            :image="data.image"
                            :sizes="['977','838','977','1129']"
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
}
</script>