<template>
    <div v-if="ready == 'idle'">
        <component
            v-for="(mod, key) in page.flexible_content"
            :key="key"
            :is="`Flex${pascalize(mod.acf_fc_layout)}`"
            :data="mod"
        ></component>
    </div>
</template>

<script>
// Loading child components
import Vue from 'vue'
// mixin for child components
const mixin = {
    props: {
        data: Object
    }
}
// require files from subfolder "/flex"
const requireComponent = require.context(
    './flex', false, /[\w]+\.vue$/
)
// register each component
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    // remove from filename './' from beginning, '.vue' at the end and ddd flex at beginning
    const componentName = `Flex${fileName.substr(0, fileName.length - 4).substr(2)}`
    // durning load add mixin for each component
    // componentConfig.default.mixins = [mixin]
    Vue.set(componentConfig.default, 'mixins', [mixin])
    // register component
    Vue.component(componentName, componentConfig.default || componentConfig)
})

export default {
    name: 'flexible-content',
    computed: {
        ready() {
            return this.$store.getters.getCmsStatus
        },
        page() {
            return this.$store.getters.getPageByPath(this.$route.path)
        }
    },
    methods: {
        pascalize(value) {
            if(value) {
                const trimmed = value.trim()
                return trimmed.substr(0, 1).toUpperCase() + trimmed.substr(1).replace(/[\W_]$/, '').replace(/[\W_]([a-zA-Z0-9])/g, (_, x) => x.toUpperCase())
            }
        }
    }
}
</script>