<template>
    <picture>
        <source
            media="(min-width: 1921px)"
            :srcset="url(image.url)"
        />
        <source
            v-if="image.sizes[`size${sizes[3]}`]"
            media="(min-width: 1416px)"
            :srcset="`${url(image.url)} 2x, ${url(image.sizes[`size${sizes[3]}`])} 1x`"
        />
        <source
            v-if="image.sizes[`size${sizes[2]}`]"
            media="(min-width: 1216px)"
            :srcset="url(image.sizes[`size${sizes[2]}`])"
        />
        <source
            v-if="image.sizes[`size${sizes[1]}`]"
            media="(min-width: 1025px)"
            :srcset="url(image.sizes[`size${sizes[1]}`])"
        />
        <source
            v-if="image.sizes[`size${sizes[0]}`]"
            media="(min-width: 320px)"
            :srcset="url(image.sizes[`size${sizes[0]}`])"
        />
        <img
            :src="image.url"
            :alt="image.alt"
            :class="classImage"
        >
    </picture>
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            required: true
        },
        sizes: {
            type: Array,
            required: true
        },
        classImage: {
            type: String,
            default: ''
        }
    },
    methods: {
        url(url) {
            return window.iOS() ? url : `${url}.webp`
        }
    }
}
</script>