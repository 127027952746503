import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import * as VueGoogleMaps from 'vue2-google-maps'
import store from './store'
import Page from '@/views/Page.vue'
import Project from '@/views/Project.vue'
import Vue2TouchEvents from 'vue2-touch-events'
let redirects = { redirections: [] }
try {
    redirects = require('../redirects.json')
} catch (error) {
}

// import styles
require('./styles/main.sass')

// register global components
const globalComponents = require.context('./components/global', true, /\.vue$/i)
globalComponents.keys().map(key => {
    Vue.component(globalComponents(key).default.name ?? key.split('/').pop().split('.')[0], globalComponents(key).default)
})

// use addons
Vue.config.productionTip = false
Vue.use(Router)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places'
    },
    installComponents: true
})
Vue.use(Vue2TouchEvents)

// initialize router
const publicPath = '/' + store.getters.getPublicPath
const locale = store.getters.getLocale ? store.getters.getLocale : 'pl'
const defaultLocale = store.getters.getOptions ? store.getters.getOptions.default_language : 'pl'
const router = new Router({
    mode: 'history',
    base: locale !== defaultLocale ? `${publicPath}${locale}/` : publicPath,
    routes: [
        ...redirects.redirections,
        { path: `/${defaultLocale}`, redirect: { name: 'home' } },
        { path: `/${defaultLocale}/:id`, redirect: '/:id' },
        { path: `/${defaultLocale}/project/:id`, redirect: '/project/:id' },
        { path: `/${defaultLocale}/:parent/:id`, redirect: '/:parent/:id' },
        { path: '/', name: 'home', component: Page },
        { path: '/:id', name: 'page', component: Page },
        { path: '/projekt/:id', name: 'project', component: Project },
        { path: '/:parent/:id', name: 'subpage', component: Page }
    ],
    scrollBehavior(to) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    VueScrollTo.scrollTo(to.hash, 1000)
                    resolve({ selector: to.hash })
                }, 1000)
            })
        } else {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                }, 400)
            })
        }
    }
})

window.iOS = () => {
    return (([
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) && !navigator.userAgent.includes('OS 14'))
}

// create Vue instance
const vm = new Vue({
    router,
    store,
    render: h => h(App)
})

store.dispatch('fetchContent').then(() => {
    vm.$mount('#app')
    //  emit "render-event" after fetching data from api endpoint
    document.dispatchEvent(new Event('render-event'))
})

// register service worker
if('serviceWorker' in navigator) {
    if (process.env.VUE_APP_MODE !== 'development') {
        navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`).then(reg => {
            reg.onupdatefound = () => {
                const installingWorker = reg.installing
                installingWorker.onstatechange = () => {
                    if(installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                        window.location.reload()
                    }
                }
            }
        })
    }
}
