<template>
    <section
        class="l-section o-logotypes"
        :class="{ 'o-logotypes--static': !data.carousel }"
    >
        <div class="l-container">
            <div class="l-row">
                <div class="l-row__column">
                    <h2 class="a-title o-logotypes__title">Dla kogo pracowaliśmy?</h2>
                </div>
            </div>
        </div>
        <div class="l-container">
            <div class="l-row">
                <div class="l-row__column o-logotypes__carouselColumn">
                    <div class="o-logotypes__carouselWrapper">
                        <div
                            class="m-carousel o-logotypes__carousel"
                            :class="{ 'o-logotypes__carousel--animated': !disableAnimation }"
                            :style="{'transform': `translateX(calc((var(--o-logotypes-carouselItem-width) + var(--o-logotypes-carouselItem-margin-right)) * -${item}))`}"
                            v-touch:swipe.left="next"
                            v-touch:swipe.right="prev"
                        >
                            <div
                                class="o-logotypes__carouselItem"
                                v-for="(logo, logoIdx) in data.logotypes"
                                v-bind:key="`logotypes-${logoIdx}`"
                            >
                                <the-image
                                    class="o-logotypes__carouselImageWrapper"
                                    class-image="o-logotypes__carouselImage"
                                    :image="logo.image"
                                    :sizes="['136','136','136','136']"
                                />
                            </div>
                            <div
                                class="o-logotypes__carouselItem"
                                v-for="(logo, logoIdx) in data.logotypes"
                                v-bind:key="`logotypes2-${logoIdx}`"
                            >
                                <the-image
                                    class="o-logotypes__carouselImageWrapper"
                                    class-image="o-logotypes__carouselImage"
                                    :image="logo.image"
                                    :sizes="['136','136','136','136']"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            item: 0,
            interval: null,
            disableAnimation: false
        }
    },
    watch: {
        item() {
            if(this.item >= 1) {
                this.disableAnimation = false
            }
        }
    },
    methods: {
        next() {
            this.item === this.data.carousel.length - 1 ? this.item = 0 : this.item++
            this.initInterval()
        },
        prev() {
            this.item === 0 ? this.item = this.data.carousel.length - 1 : this.item--
            this.initInterval()
        },
        initInterval() {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                this.item++
                if(this.item > this.data.carousel.length - 1) {
                    setTimeout(() => {
                        this.disableAnimation = true
                        this.item = 0
                    }, 800)
                }
            }, 3000)
        }
    },
    mounted() {
        if(this.data.carousel) {
            this.initInterval()
        }
    },
    destroyed() {
        clearInterval(this.interval)
    }
}
</script>