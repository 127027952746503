<template>
    <section
        class="o-cta"
        :style="{'background-image': `url(${ctaImage})`}"
    >
        <div class="l-container">
            <div class="l-row o-cta__row">
                <div
                    class="l-row__column l-row__column--4 o-cta__content1"
                    v-html="data.content_1"
                ></div>
                <div
                    class="l-row__column l-row__column--offset1 o-cta__content2"
                    v-html="data.content_2"
                ></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        ctaImage() {
            return window.iOS() ? '/img/cta.jpg' : '/img/cta.jpg.webp'
        }
    }
}
</script>
